<template>
    <div>
        <v-row>
            <v-col style="color: #90A4AE;" align="center">
                <h1 style="text-transform: uppercase;">
                    Ordre d'intervention
                </h1>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Information client
                    </h3>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete v-model="form['client']" class="edition" :items="clientListe"
                                :disabled="!isEditable" item-title="SOCIETE" return-object label="Client" variant="solo"
                                hide-details>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-card class="px-4 py-1" variant="text" style="border: solid 1px lightgray;">
                                <div style="font-size: 12px; font-weight: normal;">
                                    Ville
                                </div>
                                <div style="font-size: 19px; font-weight: normal;">
                                    {{ form['client']['VILLE'] }}
                                </div>
                            </v-card>
                            <!-- <v-text-field v-model="form['client']['VILLE']" readonly class="disabledFormData"
                                hide-details label="ville">
                            </v-text-field> -->
                        </v-col>
                        <v-col cols="6">
                            <!-- <v-text-field v-model="form['client']['CONTACT_MAIL(1)']" class="disabledFormData"
                                variant="solo" disabled hide-details label="Mail">
                            </v-text-field> -->
                            <v-card class="px-4 py-1" variant="text" style="border: solid 1px lightgray;">
                                <div style="font-size: 12px; font-weight: normal;">
                                    Mail
                                </div>
                                <div style="font-size: 19px; font-weight: normal;">
                                    {{ form['client']['CONTACT_MAIL(1)'] }}
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col v-if="step >= 2">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Theme devis
                    </h3>
                    <v-textarea v-model="form['themeDevis']" label="Raison de l'intervention..." :disabled="!isEditable"
                        class="edition" variant="solo" hide-details></v-textarea>
                </div>
            </v-col>
            <v-col cols="12" v-if="step >= 2" id="date">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Date / Horaire estimé
                    </h3>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form['date']" hide-details class="edition" type="date" variant="solo"
                                :disabled="!isEditable" label="Date d'intervention"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="form['timeStart']" hide-details class="edition" type="time"
                                :disabled="!isEditable" variant="solo" label="Heure de départ"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="form['timeEnd']" hide-details class="edition" type="time" variant="solo"
                                :disabled="!isEditable" label="Heure de fin"></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="12" v-if="step >= 2" id="machine">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Information machine
                    </h3>
                    <v-col cols="12">
                        <v-combobox class="edition" v-model="form['materiel']" return-object item-title="MATRICULE"
                            :items="parcListe" hide-details label="Matricule" variant="solo" :disabled="!isEditable">
                        </v-combobox>
                    </v-col>
                    <v-col v-if="form['materiel']">
                        <v-row>
                            <!-- <v-card class="px-4 py-1" variant="text" style="border: solid 1px lightgray;"> -->
                            <v-col cols="6" v-for="elem in materielElemToShow" :key="elem.key" >
                                <v-card class="px-4 py-1" variant="text" style="border: solid 1px lightgray;">
                                    <div style="font-size: 12px; font-weight: normal;">
                                        {{ elem.label }}
                                    </div>
                                    <div style="font-size: 19px; font-weight: normal;">
                                        {{ form['materiel'][elem.key] }}
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field class="edition" v-model="form['Kilometrage']" hide-details
                                    label="Trajet en KM" variant="solo" :disabled="!isEditable">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field class="edition" v-model="form['Horametre']" hide-details
                                    label="Compteur horaire" variant="solo" :disabled="!isEditable">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
            </v-col>
            <v-col cols="12" v-if="step >= 3" id="techn">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Technicien(s)
                    </h3>
                    <v-select class="edition" v-model="form['technicien']" :items="technicienListe" return-object
                        :item-title="'NOM_TECHNICIEN'" hide-details variant="solo" :disabled="!isEditable">
                    </v-select>
                </div>
            </v-col>
            <v-col cols="12" v-if="step >= 4">
                <div class="stepContainer">
                    <h3 class="pa-3 pl-0" style="text-transform:uppercase ;">
                        Accessoire(s)
                    </h3>
                    <v-row align=center class="mb-2">
                        <v-col cols="">
                            <v-combobox class="edition" v-model="selectedAccessoire" :items="stockListe"
                                item-title="PIECE_DESIGNATION" hide-details label="Sélectionner un accessoire"
                                variant="solo" :disabled="!isEditable"></v-combobox>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn @click="addAcc" :disabled="!isEditable">Ajouter</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <Vue3EasyDataTable hide-footer :headers="headers" :items="items" header-text-direction="center"
                                body-text-direction="center" @click-row="clickRow" empty-message="Aucun accesoire ajouté">
                                <template #item-qte="item">
                                    <v-col class="pa-0">
                                        <v-text-field v-model="items.find((val) => val.id == item.id).qte" type="number"
                                            hide-details density="compact" :disabled="!isEditable"></v-text-field>
                                    </v-col>
                                </template>
                            </Vue3EasyDataTable>
                        </v-col>
                        <v-col align=center cols="12" v-if="isEditable">
                            <v-btn @click="validForm" color="#81C784" style="color:white">
                                Valider l'intervention
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>


import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import { uuid } from 'vue-uuid'
import engine from '../core/DataApi'
import moment from 'moment'
import { ref } from "@vue/reactivity";
import { watch } from "vue";

export default {
    props: {
        technicienListe: {},
        agenceListe: {},
        FormData: {},
        clientListe: {},
        parcListe: {},
        stockListe: {},
        itemsData: {}
    },
    setup() {
        const form = ref({})
        const step = ref(1)

        watch(() => form, (currentValue) => {
            let keys = [
                { key: ['technicien'], step: 4, id: "techn" },
                { key: ['materiel'], step: 3, id: "techn" },
                // { key: ['date', 'timeStart', 'timeEnd'], step: 3, id: "machine" },
                { key: ['client'], step: 2, id: "date" },
            ]

            for (let i = 0; i < keys.length; i++) {
                if (keys[i].key.filter((val) => currentValue.value[val] == undefined).length == 0) {
                    step.value = keys[i].step
                    setTimeout(function () {
                        let elem = document.getElementById(keys[i].id);
                        if (elem)
                            elem.scrollIntoView({ behavior: 'smooth' }, true);
                    }, 500);
                    break
                }
            }
        },
            { deep: true }
        );

        return { form, step }
    },
    data() {
        return {
            isEditable: true,
            uuid: uuid.v1(),
            selectedAccessoire: undefined,
            headers: [
                { text: "Référence", value: "PIECE_REFERENCE", sortable: true },
                { text: "Désignation", value: "PIECE_DESIGNATION", sortable: true },
                { text: "Quantitée", value: "qte", sortable: true, width: '200' },
            ],
            items: []
        }
    },
    mounted() {
        if (this.itemsData) {
            this.preloadData()
            if (this.itemsData['Status devis'] == 'Signé')
                this.isEditable = false
        }else{
            this.form["date"] = moment().format('YYYY-MM-DD')
            this.form["timeStart"] = moment().set('m', 0).format('HH:mm')
            this.form["timeEnd"] = moment().add(1,'h').set('m', 0).format('HH:mm')
        }
    },
    components: {
        Vue3EasyDataTable
    },
    methods: {
        preloadData() {
            console.log("client1", this.clientListe);
            console.log("client2", this.itemsData);
            let that = this

            this.form["id"] = this.itemsData['id']
            this.form['client'] = this.clientListe.find((val) => val.SOCIETE == this.itemsData.societeliv)
            this.form["link"] = this.itemsData['_LINK MOTEUR']
            this.form["materiel"] = this.parcListe.find((val) => val.MATRICULE == this.itemsData["N° MATR INT"])
            this.form["Horametre"] = this.itemsData['Horametre']
            this.form["Kilometrage"] = this.itemsData['Kilometrage']
            this.form["themeDevis"] = this.itemsData['Theme devis']

            if (this.itemsData['DATE RAPPORT']){
                let dateFormated = this.itemsData['DATE RAPPORT'].split('/')
            this.form["date"] = dateFormated[2] + '-' + dateFormated[0] + '-' + dateFormated[1]
            }

            this.form["timeStart"] = this.itemsData['Heure Arriv(1)']
            this.form["timeEnd"] = this.itemsData['Heure dep(1)']

            // this.form['technicien'] = []
            // for (let i = 0; i < 5; i++) {
            //     let key = 'Techniciens_Nom(' + i + ')'
            //     if (this.itemsData[key] != undefined && this.itemsData[key].length != 0) {
            //         this.form['technicien'].push(this.technicienListe.find((val) => val.NOM_TECHNICIEN == this.itemsData[key]))
            //     }
            // }
            this.form['technicien'] = this.itemsData['Techniciens_Nom(1)']

            engine.get_orListe_byLink(this.itemsData['_LINK MOTEUR'], function (data) {
                if (data.code == 0) {
                    let moteurListe = data.data.data.map((val) => val.fieldData)
                    for (let i = 0; i < moteurListe.length; i++) {
                        let elem = that.stockListe.find((val) => val.PIECE_REFERENCE == moteurListe[i]['SAV REF PRODUIT'])
                        elem.qte = moteurListe[i]["SAV QTE PROD"]
                        that.items.push(elem)
                    }
                }
            })

        },
        verifScroll(elem) {
            if (elem) {
                return true
            } else {
                return false
            }
        },
        validForm() {
            let that = this
            let id = uuid.v1()
            let link = uuid.v1()
            // let clientAgency = this.agenceListe.find((val) => val.ag_nom == this.form['client']['ID Agences::ag_nom'])
            console.log("form", this.form);
            let finalObj = {
                id,
                societeliv: this.form['client']['SOCIETE'],
                // 'N° RAP COMP': '?',
                // 'N° Dev COMP': '?',
                // 'Observ': '?',
                // 'dptliv': '?',
                // 'N° serie constr.': '?',
                '_LINK MOTEUR': link,
                'Telephone': this.form['client']['TELEPHONE'],
                'villeliv': this.form['client']['VILLE_LIV'],
                // 'dptliv': clientAgency.CODE_POSTAL,
                // 'ID_Agence': clientAgency.ID_Agence,
                // 'ID_Societe': clientAgency.ID_Societe,
                'N° MATR INT': this.form['materiel']['MATRICULE'],
                'Horametre': this.form['Horametre'],
                'Kilometrage': this.form['Kilometrage'],
                'MARQUE MACHINE': this.form['materiel']['MARQUE'],
                'TYPE MACHINE': this.form['materiel']['TYPE'],
                'DATE RAPPORT': moment(this.form['date']).format('DD/MM/YYYY'),
                'Theme devis': this.form['themeDevis'],
                'Heure Arriv': this.form['timeStart'],
                'Heure dep': this.form['timeEnd'],
                "Status devis": 'en cours',
            }
            // for (let i = 0; i < this.form.technicien.length; i++) {
            //     finalObj['Techniciens_Nom(' + (i + 1) + ')'] = this.form.technicien[i].NOM_TECHNICIEN
            // }
            finalObj['Techniciens_Nom(1)'] = this.form.technicien.NOM_TECHNICIEN
            
            console.log("loaded");
            engine.creat_new_or(finalObj, function (data) {
                console.log("creat_new_or", data);
                if (data.code == 0) {
                    if (that.itemsData && that.itemsData.id)
                        engine.delete_or_by_id(that.itemsData.id, function () {
                        })

                    if (that.items.length == 0)
                        that.$emit('close')
                    for (let i = 0; i < that.items.length; i++) {
                        let moteurInfo = that.items[i]
                        let elem = {
                            id: moteurInfo.id,
                            "_LINK SAV": link,
                            "id_SAV": id,
                            "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                            "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                            "SAV QTE PROD": moteurInfo.qte,
                            "SAV PV UNIT": undefined,
                            "PIECE_TOTAL": undefined,
                            "FLAG_DELETE": undefined,
                            // ID_Societe: clientAgency.ID_Societe,
                            Index_Ligne: i + 1,
                        }
                        engine.creat_new_moteur_line(elem, function () {
                            if (i + 1 == that.items.length) {
                                that.$emit('close')
                            }
                        })
                    }
                }
            })
        },
        addAcc() {
            this.selectedAccessoire['qte'] = 1
            this.items.push(this.selectedAccessoire)
            this.selectedAccessoire = undefined
        },
        clickRow(data) {
            console.log("clickRow", data);
        }
    },
    computed: {
        materielElemToShow() {
            return [{
                key: "FAMILLE",
                label: "Famille"
            },
            {
                key: "SOUS_FAMILLE",
                label: "Sous famille"
            }, {
                key: "NUMERO_SERIE",
                label: "Numéro de série"
            }, {
                key: "MARQUE",
                label: "Marque"
            }]
        }
    }
}
</script>

<style>
.edition {
    background-color: #EEEEEE;
    color: black;
    border: none !important;
}

.disabledFormData {
    background-color: white !important;
}

.stepContainer {
    background-color: #ECEFF1;
    padding: 15px;
    border-radius: 10px;
}
</style>