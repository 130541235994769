<template>
  <div style="position: relative;">
    <div id="mapContainer"></div>
    <div style="position: absolute; right: 10px; top: 15px; z-index: 3">
      <v-card class="py-1 px-2" variant="flat" style="font-weight: bold; background-color: rgb(255, 255, 255, 0.8)">
        Distance :
        {{ getItineraireTotalSummary.totalDistance }} | Temps :
        {{ getItineraireTotalSummary.totalTime }} | Poids MAX :
        {{ getMaxPoid(getItineraireTotalSummary.totalPoid) }}
        Kg
      </v-card>
    </div>
    <div style="
      position: absolute;
      right: 0px;
      top: 50px;
      max-height: 53.5vh;
      overflow-y: auto;
    " class="pr-2">
      <!-- {{ itineraireOrder.data }} -->
      <!-- {{ itineraireDataSaved}} -->
      <!-- {{ itineraireOrder }} -->
      <v-timeline v-if="itineraireOrder.data.length != 0" align="start" side="end">
        <v-timeline-item v-for="(item, i) in itineraireOrder.data" :key="i" size="x-small" width="300px"
          :dot-color="item.color">
          <v-card :id="'timeline-item-' + i" class="pa-3" variant="flat" style="
                                    opacity: 1;
                                    background-color: rgba(255, 255, 255, 0.7);
                                    border: solid 1px lightgrey;
                                    width: 100%;
                                    ">
            <div class="pa-2 pb-1 mb-2" align="center"
              style="color: #f5f5f5; font-weight: bold;width: 100%;border-radius: 3px;"
              :style="{ 'background-color': '#757575' }">
              {{ i + 1 }} - {{ item.clients[0] }}
              <v-icon> mdi-arrow-right-bold </v-icon> <br>
              {{ item.clients[1] }}
            </div>
            <v-col class="pt-1">
              <v-row justify="center" align="center">
                <v-col cols="auto" class="pa-0 pt-2 pb-1">
                  <v-btn style="border: solid 1px; background-color: white; opacity: 1" color="#66BB6A" variant="plain"
                    density="compact" @click="setItineraireOrderUp(item)">
                    <v-icon>mdi-arrow-up-bold</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="pa-0 pt-2 pb-1">
                  <v-btn class="mx-1" style="border: solid 1px; background-color: white; opacity: 1" color="#5C6BC0"
                    variant="plain" density="compact" @click="setItineraireOrderDown(item)">
                    <v-icon>mdi-arrow-down-bold</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="pa-0 pt-2 pb-1">
                  <v-btn style="border: solid 1px; background-color: white; opacity: 1" color="#EF5350" variant="plain"
                    density="compact" @click="deleteFromItineraire(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="px-0">
              <v-expansion-panels>
                <v-expansion-panel elevation="0" style="border:solid 1px lightgray">
                  <v-expansion-panel-title style="box-shadow: none;" class="elevation-0 text">
                    Information complémentaire
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="pa-0 ma-0">
                    <div class="pt-2">
                      <v-col class="px-0 py-1" cols="12">
                        <v-select location="left" hide-selected multiple label="Materiel à récupérer"
                          no-data-text="Aucun materiel à récupérer"
                          :disabled="getDispoSortieMaterielListe(item).length == 0"
                          :items="getDispoSortieMaterielListe(item)" return-object v-model="item.matriculeListeSortie"
                          hide-details density="compact" variant="solo" style="border-style: none;" closable-chips
                          item-title="title" chips>
                          <template v-slot:item="{ item, props }">
                            <div v-bind="props">
                              <v-card class=" pa-2" width="300" variant="text"
                                style="background-color: #EEEEEE;border:solid 1px lightgrey;cursor:pointer">
                                Fin du Contrat : {{ item.value.CLD_DATE_RETOUR }} <br>
                                Matricule : {{ item.value.CLD_MATRICULE }} <br>
                                Description : {{ item.value.CLD_DESCRIPTION }} <br>
                                Contrat : {{ item.value.CLD_NUM_CONTRAT }} <br>
                              </v-card>
                            </div>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col class="pa-0" cols="12">
                        <v-select location="left" hide-selected multiple label="Materiel à livrer"
                          no-data-text="Aucun materiel à livrer" :items="getDispoStockMaterielListe(item)"
                          :disabled="getDispoStockMaterielListe(item).length == 0" return-object
                          v-model="item.matriculeListeStock" hide-details density="compact" variant="solo"
                          style="border-style: none;" closable-chips item-title="title" chips>
                          <template v-slot:item="{ item, props }">
                            <div v-bind="props">
                              <v-card class="pa-2 materiel-selection" width="300" variant="text">
                                Fin du Contrat : {{ item.value.CLD_DATE_RETOUR }} <br>
                                Matricule : {{ item.value.CLD_MATRICULE }} <br>
                                Description : {{ item.value.CLD_DESCRIPTION }} <br>
                                Contrat : {{ item.value.CLD_NUM_CONTRAT }} <br>
                              </v-card>
                            </div>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" class="px-0 py-1">
                        <v-text-field style="background-color: white" hide-details density="compact" label="Poid en kg"
                          variant="solo" class="mt-1" v-model="item.poid"></v-text-field>
                      </v-col>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-card class="white text--primary pa-2" variant="text" style="background-color: #BDBDBD;">
              <v-menu transition="slide-y-transition" :location="'start'" open-delay="0" open-on-hover close-delay="0">
                <template v-slot:activator="{ props }">
                  <v-row>
                    <v-col v-bind="props" class="pa-1 trajetListe" align=center style="cursor:pointer">
                      <v-icon v-bind="props" class="pa-0 ma-0" color="#FAFAFA" size="20"> mdi-information</v-icon>
                    </v-col>
                  </v-row>
                </template>
                <v-card style="border: solid 1px lightgrey">
                  <v-col class="pa-0">
                    <div style="text-transform: uppercase; font-weight: bold" class="pa-2" align="center">
                      trajets conseillée
                    </div>
                    <v-col v-for="(path, i) in itineraireDataSaved[item.key]" :key="i" class="" style="color:#FAFAFA"
                      align="center" :style="{
                        'background-color': i % 2 == 0
                          ? '#303F9F'
                          : '#455A64',
                      }">
                      <div>

                        <div v-if="path.name.length != 0">
                          <v-chip density="compact">{{ i + 1 }}</v-chip> {{ path.name }}
                        </div>
                        {{ (path.summary.totalDistance / 1000).toFixed(1) }}
                        km /
                        {{ (path.summary.totalTime / 60).toFixed(0) }}
                        min
                      </div>
                    </v-col>
                  </v-col>
                </v-card>
              </v-menu>
            </v-card>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
    <div v-if="mapIsLoading == true" style="
            position: absolute;
            top: 0px;
            padding-top: 25vh;
            background-color: rgb(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            z-index: 3;
        " class="pr-2" align="center">
      <v-progress-circular :size="120" color="primary" indeterminate></v-progress-circular>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    mapIsLoading: {},
    getItineraireTotalSummary: {},
    itineraireOrder: {},
    setItineraireOrderUp: {},
    setItineraireOrderDown: {},
    deleteFromItineraire: {},
    getMaxPoid: {},
    itineraireDataSaved: {},
    gpsPositionByClient: {},
  },
  data() {
    return {
      isloading: true,
    }
  },
  methods: {
    getDispoSortieMaterielListe(item) {
      let dataSortie = this.gpsPositionByClient[item.idFrom].dataSortie
      let finalData = []
      if (dataSortie && dataSortie.length != 0)
        for (let i = 0; i < dataSortie.length; i++) {
          let softData = {
            title: dataSortie[i].CLD_NUM_CONTRAT + ' - ' + dataSortie[i].CLD_MATRICULE,
            CLD_DESCRIPTION: dataSortie[i].CLD_DESCRIPTION,
            CLD_MATRICULE: dataSortie[i].CLD_MATRICULE,
            CLD_NUM_CONTRAT: dataSortie[i].CLD_NUM_CONTRAT,
            CLD_SOUS_FAMILLE: dataSortie[i].CLD_SOUS_FAMILLE,
            CLD_DATE_RETOUR: dataSortie[i].CLD_DATE_RETOUR,
          }
          finalData.push(softData)
        }
      return finalData
    },
    getDispoStockMaterielListe(item) {
      let dataStock = this.gpsPositionByClient[item.idFrom].dataStock
      let finalData = []
      if (dataStock && dataStock.length != 0)
        for (let i = 0; i < dataStock.length; i++) {
          let softData = {
            title: dataStock[i].CLD_NUM_CONTRAT + ' - ' + dataStock[i].CLD_MATRICULE,
            CLD_DESCRIPTION: dataStock[i].CLD_DESCRIPTION,
            CLD_MATRICULE: dataStock[i].CLD_MATRICULE,
            CLD_NUM_CONTRAT: dataStock[i].CLD_NUM_CONTRAT,
            CLD_SOUS_FAMILLE: dataStock[i].CLD_SOUS_FAMILLE,
            CLD_DATE_RETOUR: dataStock[i].CLD_DATE_RETOUR,
          }
          finalData.push(softData)
        }
      return finalData
    }
  },
  computed: {
    getSize() {
      return this.itineraireOrder.data.length
    }
  },
  watch: {
    getSize(val) {
      let id = "timeline-item-" + (val - 1)
      // if (document.getElementById(id) != null)
      setTimeout(function () {
        document.getElementById(id).scrollIntoView({ bottom: 'scrollDiv-0', behavior: 'smooth' })
      }, 100)
    },
  }
};
</script>

<style></style>