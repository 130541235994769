<template>
  <div>
    <v-dialog v-model="removeEtadlData.value">
      <div align="center">
        <v-card width="95%" max-width="300px">
          <v-col style="font-weight:bold;font-size:20px">
            Valider la suppression </v-col>
          <v-col class="px-0">
            <v-row>
              <v-col cols="6" class="px-0">
                <v-btn @click="deleteEtadlValidation(removeEtadlData.data)" style="color:white" color="#E57373">
                  OUI
                </v-btn>
              </v-col>
              <v-col cols="6" class="px-0">
                <v-btn @click="removeEtadlData.value = false" style="color:white" color="#5C6BC0">
                  NON
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-if="isqCodeReaderOpen" v-model="isqCodeReaderOpen" width="99%" height="500" max-width="500">
      <v-card min-height="300">
        <qcode-reader :setMaterialList="setMaterialList"></qcode-reader>
      </v-card>
    </v-dialog>
    <v-dialog v-if="modalUser" v-model="modalUser" width="99%" max-width="500" persistent>
      <v-card height="80vh">
        <v-col>
          <modalUserDialog @close="modalUser = false" :agencyList="agency_list"
            :get_user_information="get_user_information" :isAdmin="isAdmin" />
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog" v-model="dialog" fullscreen style="border-radius:0px">
      <div style="height:100vh; overflow:auto">
        <materielForm v-if="dialogData" :showErrorInContrat="showErrorInContrat" :drawInObservation="drawInObservation" :multipleSelection="multipleSelection" :isAdmin="isAdmin" :nbrMaxLoadedPhoto="nbrMaxLoadedPhoto" :nbrMinLoadedPhoto="nbrMinLoadedPhoto"
          :dialog="dialog" :data="dialogData" :clients_list="clients_list" :user_information="get_user_information"
          :agencyList="agency_list" :defaultForm="defaultForm" :defaultFormValues="defaultFormValues" :showSignByMail="showSignByMail"
          @closeDialog="dialog = false" :getNbrPhotoToLoad="getNbrPhotoToLoad" @pdfLoaded="nbrUploadDone = nbrUploadDone + 1" :nbrUploadDone="nbrUploadDone" @newMaterielForm="newMaterielForm($event)" />
      </div>
    </v-dialog>
    <div v-if="isLoading || materials_list == undefined" style="
        position: fixed;
        background-color: rgb(0, 0, 0, 0.5);
        height: 100%;
        width: 100vw;
        z-index: 5;
        top: 0px;
        left: 0px;
      ">
      <v-col align="center" style="margin-top: 35vh">
        <v-progress-circular size="120" indeterminate color="white">Chargement</v-progress-circular>
      </v-col>
    </div>
    <v-col v-if="materials_list" class="px-0 py-0 etat-des-lieux-materiel" align="center">
      <div style="position: absolute;z-index: 10; right: 10px">
        <!-- <v-btn v-if="isAdmin != 0" @click="modalUser = true" :color="textColor" icon="mdi-cog" size="small"
          variant="text">
        </v-btn> -->
        <v-btn @click="isqCodeReaderOpen = true" style="" :color="textColor" icon="mdi-qrcode-scan" size="small"
          variant="text"></v-btn>
        <v-divider vertical class="mx-1"></v-divider>
        <v-btn @click="updateTable" style="" :color="textColor" icon="mdi-refresh" size="small" variant="text"></v-btn>
      </div>
      <v-card class="py-1 mb-1" :style="{ 'background-color': secondColor, color: textColor }"
        style="font-size: 20px; font-weight: bold;border-radius: 5px;">
        Tableau de bord
      </v-card>
      <v-card class="pa-1" :color="secondColor">
        <v-col class="">
          <v-row class="" justify="center" align="center">
            <v-col cols="12" :md="showAgencySelector ? '' : '12'" class="pa-1 py-0">
              <v-text-field style="height:68px" class="mt-2" variant="solo" hide-details clearable density="default"
                label="Recherche -  Contrat / Client / Matricule / Description" v-model="search"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1 pb-1">
              <v-select v-if="itemsMenu" label="Type de contrat à afficher" density="default" hide-details variant="solo"
                style="text-transform: uppercase" :items="getItemsMenuFiltered" bg-color="white" item-title="text" item-value="idx"
                v-model="selectedItem" multiple>
                <template v-slot:selection="{ item }">
                  <v-chip style="color:black !important;border:none;padding: 0px !important 10px !important;"
                    :class="itemsMenu[item.value].class"
                    >
                    <span>{{ item.title }} </span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="showAgencySelector" cols="6" md="6" class="pa-1">
              <v-select density="default" variant="underlined" bg-color="white" label="Agence" v-model="selectedAgency"
                :items="agency_list" hide-details item-title="ag_nom" class="pa-2" item-value="ID_Agence"
                style=" border-radius:4px; background-color: white; text-transform: uppercase"
                no-data-text="Aucune agence enregistré" return-object>
              </v-select>
            </v-col>
            <v-col  :cols="showAgencySelector == true ? '6' : '12'" md="6" class="pa-1">
              <v-autocomplete class="pa-1" bg-color="white" label="Rechercher une sous famille" 
              :items="getSubFamilly"
                density="default" v-model="selectedSubFamilly" hide-details
                style="border-radius:3px;  background-color: white; text-transform: uppercase"
                no-data-text="Aucune sous famille enregistré" :menu-props="{ maxHeight: 500 }" clearable
                >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
      <v-col class="pt-1 pb-0 px-0" align="center">
        <v-card style="border-radius:5px" :color="secondColor" class="pa-1">
          <!-- :style="{ height: getTableHeight }" -->
          <Vue3EasyDataTable table-class-name="customize-table" ref="dataTable" :headers="get_tab_header"
            :rows-per-page="nbrPagination" :items="get_tab_items" style="overflow: auto" :table-height="getTableHeight"
            rows-per-page-message="Ligne par colonne" @click-row="openDialog" empty-message="Aucun contrat trouvé"
            hide-footer>
            <template #header-CLD_DATE_DEPART="header">
              <div class="customize-header">
                {{ header.text }}
                <v-icon @click="
  sortDateFilter == 1
    ? (sortDateFilter = 2)
    : (sortDateFilter = 1)
" class="pa-2 mb-1 ml-1 sort" style="
                      font-size: 16px;
                      cursor: pointer;
                      border: solid 1px lightgrey;
                      border-radius: 100%;
                    ">
                  mdi-swap-vertical</v-icon>
              </div>
            </template>
            <template #header-CLD_DATE_RETOUR="header">
              <div class="customize-header">
                {{ header.text }}
                <v-icon @click="
  sortDateFilter == 3
    ? (sortDateFilter = 4)
    : (sortDateFilter = 3)
" class="pa-2 mb-1 ml-1 sort" style="
                      font-size: 16px;
                      cursor: pointer;
                      border: solid 1px lightgrey;
                      border-radius: 100%;
                    ">
                  mdi-swap-vertical</v-icon>
              </div>
            </template>
            <template #item-CLD_ACTION="item">
              <div class="customize-header">
                <v-chip class="my-1" :class="getItemType(item.CLD_CONTROLE_DEPART, item.CLD_CONTROLE_RETOUR, item.CLD_WAITING).class"
                  style="color:black;">
                  {{ item.CLD_ACTION }}
                </v-chip>
              </div>
            </template>
            <template #item-CLD_DATE_DEPART="item">
              <div class="customize-header">
                {{ formatDate(item.CLD_DATE_DEPART, "ll") }}
              </div>
            </template>
            <template #item-CLD_DATE_RETOUR="item">
              <div class="customize-header">
                {{ formatDate(item.CLD_DATE_RETOUR, "ll") }}
              </div>
            </template>
            <template #item-CLD_DATE_LIVRAISON_DEPART="item">
              <div class="customize-header">
                {{ formatDate(item.CLD_DATE_LIVRAISON_DEPART, "ll") }}
              </div>
            </template>
            <template #item-CLD_DATE_LIVRAISON_RETOUR="item">
              <div class="customize-header">
                {{ formatDate(item.CLD_DATE_LIVRAISON_RETOUR, "ll") }}
              </div>
            </template>
            <template #item-CLD_MODIFICATION="item">
              <div class="customize-header">
                {{ formatDate(item.CLD_MODIFICATION, "lll") }}
              </div>
            </template>
            <template #item-delete="item">
              <div class="customize-header">
                <v-btn color="#E57373" density="compact" @click="deleteEtadl({ value: true, data: item })">
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </Vue3EasyDataTable>
          <v-pagination v-model="currentPaginationNumber" style=";background-color: white;" class="" rounded="circle"
            density="comfortable" :length="maxPaginationNumber"></v-pagination>
        </v-card>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import materielForm from "./materielForm";
import modalUserDialog from "./modalUser";
import qcodeReader from "./qcodeReader";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { ref } from "@vue/reactivity";
import homePageData from '../hooks/page-home'
import "vue3-easy-data-table/dist/style.css";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const nbrPagination = ref(50);
    const currentPaginationNumber = ref(1);
    const page = ref(1);
    const pageCount = ref(0);
    const itemsPerPage = ref(15);

    const maxPaginationNumber = computed(function () {
      return Math.ceil(get_tab_items.value.length / nbrPagination.value);
    })

    const getItemsMenuFiltered = computed(function(){
      return itemsMenu.value.filter((val) => val.idx != 1 && val.idx != 4)
    })

    const {
      textColor,
      secondColor,
      selectedSubFamilly,
      isLoading,
      isqCodeReaderOpen,
      dialog,
      modalUser,
      search,
      selectedItem,
      selectedMaterial,
      dialogData,
      materials_list,
      getSubFamilly,
      dataMenu,
      itemsMenu,
      get_client_state,
      get_tab_items,
      get_tab_header,
      get_selected_material_data,
      get_depart_material,
      get_waiting_material,
      get_return_material,
      get_user_information,
      isAdmin,
      showAgencySelector,
      clients_list,
      nbrMaxLoadedPhoto,
      nbrMinLoadedPhoto,
      agency_list,
      defaultForm,
      defaultFormValues,
      getTableHeight,
      removeEtadlData,
      newMaterielData,
      nbrUploadDone,
      drawInObservation,
      multipleSelection,
      showErrorInContrat,
      showSignByMail,
      getItemType,
      formatDate,
      setPhoto,
      uploadPhoto,
      deleteAllPhotos,
      updateTable,
      user,
      newMaterielForm,
      get_materials_data_to_show,
      closeDialog,
      updateCalendarDaysToShow,
      openDialog,
      setMaterialList,
      deleteEtadl,
      deleteEtadlValidation,
      getNbrPhotoToLoad
    } = homePageData()


    return {
      nbrPagination,
      currentPaginationNumber,
      page,
      pageCount,
      itemsPerPage,
      textColor,
      secondColor,
      selectedSubFamilly,
      isLoading,
      isqCodeReaderOpen,
      dialog,
      modalUser,
      search,
      selectedItem,
      selectedMaterial,
      dialogData,
      materials_list,
      getSubFamilly,
      dataMenu,
      itemsMenu,
      get_client_state,
      get_tab_items,
      get_tab_header,
      get_selected_material_data,
      get_depart_material,
      get_waiting_material,
      get_return_material,
      get_user_information,
      isAdmin,
      showAgencySelector,
      maxPaginationNumber,
      clients_list,
      nbrMaxLoadedPhoto,
      nbrMinLoadedPhoto,
      agency_list,
      defaultForm,
      defaultFormValues,
      getTableHeight,
      removeEtadlData,
      newMaterielData,
      nbrUploadDone,
      drawInObservation,
      multipleSelection,
      showErrorInContrat,
      showSignByMail,
      getItemType,
      formatDate,
      setPhoto,
      uploadPhoto,
      deleteAllPhotos,
      updateTable,
      user,
      newMaterielForm,
      get_materials_data_to_show,
      closeDialog,
      updateCalendarDaysToShow,
      openDialog,
      setMaterialList,
      deleteEtadl,
      deleteEtadlValidation,
      getNbrPhotoToLoad,
      getItemsMenuFiltered
    }
  },
  data() {
    return {
      removeEtadl: {
        value: false,
        data: {}
      }
    }
  },
  components: {
    Vue3EasyDataTable,
    materielForm,
    modalUserDialog,
    qcodeReader,
  },
  watch: {
    currentPaginationNumber(val) {
      this.$refs["dataTable"].updatePage(val);
    },
  },
  methods: {
    goToPath(href) {
      this.$router.push(href);
    },
  }
};
</script>

<style>
@import "../style.css";
</style>