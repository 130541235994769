<template>
    <v-dialog v-model="isSignatureOpen" align="center">
        <v-col style="">
            <v-card height="500" min-width="200" max-width="500" style="border-radius: 0px;">
                <v-icon @click="isSignatureOpen = false" style="position: absolute;right:5px;z-index: 2" color="#E57373">
                    mdi-close-circle
                </v-icon>
                <v-btn v-if="isEditable" @click="undo" size="20" variant="flat"
                    style="position:absolute;right:10px;bottom:10px;color: #424242;z-index: 2;font-size: 10px;"
                    icon="mdi-undo" color="#E0E0E0">
                </v-btn>
                <signature-pad v-if="isEditable" style="position: absolute;top:0px;" :ref="'signaturePad'" :height="500"
                    :width="500" :options="{
                        penColor: 'black',
                        minWidth: 1,
                        maxWidth: 1,
                    }" @input="onInput" />
            </v-card>
            <v-btn width="500" @click="valideSignature()"
                style="border-radius:0px; background-color: #66BB6A;color: white;">
                Valider ma signature
            </v-btn>
        </v-col>
    </v-dialog>
    <v-card width="auto" v-if="isLoading == false">
        <div style="position: relative;">
            <!-- <object id="pdfViewer" :data="fileAndSignature" type="application/pdf"
                width="100%" style="height:93.3vh">
            </object> -->
            <vue-pdf-app style="height: 94.2vh;" :config="config" :pdf="fileAndSignature"></vue-pdf-app>
            <v-col class="pt-0" style="background-color: #E0E0E0;position: fixed; bottom: 0px;">
                <v-row justify="center" align="center" class="pt-2">
                    <v-col align="center" v-if="isEditable">
                        <v-btn @click="isSignatureOpen = true" color="#66BB6A"
                            style="color: white;width: 100%;">Signer</v-btn>
                    </v-col>
                    <v-col align="center" v-if="isEditable">
                        <v-btn @click="sendNewPdfToServer()" color="#66BB6A"
                            style="color: white;width: 100%;">Enregistrer</v-btn>
                    </v-col>
                    <v-col align="center">
                        <v-btn @click="closeModal" color="#E57373" style="color: white;width: 100%;">Fermer</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </div>
    </v-card>
    <div v-else style="margin-top:40vh" align=center>
        <v-progress-circular :size="160" :width="7" color="#7986CB" indeterminate>
            Chargement</v-progress-circular>
    </div>
</template>
<script>

import engine from '../core/index'
import SignaturePad from "vue3-signature-pad";
import moment from 'moment'

import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";

// import pdfjsLib from 'pdfjs-dist';
// const { PDFDocument, StandardFonts, rgb } = require('pdf-lib');
const { PDFDocument } = require('pdf-lib');

const Buffer = require('buffer').Buffer

export default {
    data() {
        return {
            isLoading: true,
            fileUrl: undefined,
            fileSize: { height: 841, width: 594 },
            signatureBase64: undefined,
            fileAndSignature: undefined,
            isSignatureOpen: false,
            config: {
                sidebar: false
                // toolbar: {
                //     toolbarViewerLeft: false
                // }
            }
        }
    },
    mounted() {
        let that = this
        engine.get_file_from_path(this.fileIsSelectedData.path, function (data) {
            if (data.code == 0) {
                let b64 = "data:application/pdf;base64," + new Buffer.from(data.data.fileBinary).toString('base64');
                that.fileUrl = b64
                that.fileAndSignature = b64
                that.isLoading = false
            }
        })
    },
    methods: {
        async addSignatureInFile() {
            let pdfDoc = await PDFDocument.load(this.fileUrl);
            if (this.signatureBase64) {
                let image = await pdfDoc.embedPng(this.signatureBase64);
                let page = pdfDoc.getPages()[0];
                let posX = parseFloat(this.fileIsSelectedData.position[0])
                let posY = parseFloat(this.fileIsSelectedData.position[1])

                // posX = 350 
                // posY = 75

                page.drawImage(image, {
                    x: posX,  // coordonnée X de la position de l'image
                    y: posY,  // coordonnée Y de la position de l'image
                    width: 150,  // largeur de l'image
                    height: 100,  // hauteur de l'image
                });

                // Écrire le texte sur la page
                let font = await pdfDoc.embedFont('Helvetica');
                let fontSize = 10;
                page.drawText('Signée le ' + moment().format('DD/MM/YYYY HH:mm'), {
                    x: posX + 50,  // coordonnée X de la position de l'image
                    y: posY + 15,  // coordonnée Y de la position de l'image
                    size: fontSize,
                    font,
                });

            }
            const pdfBytes = await pdfDoc.save();
            const pdfUint8Array = new Uint8Array(pdfBytes);
            const base64String = "data:application/pdf;base64," + Buffer.from(pdfUint8Array).toString('base64');
            this.fileAndSignature = base64String
        },
        valideSignature() {
            this.addSignatureInFile()
            this.isSignatureOpen = false
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        closeModal() {
            this.$emit('closeModal')
        },
        onInput() {
            this.save(false)
        },
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        sendNewPdfToServer() {
            let that = this
            this.isLoading = true

            let finalData = undefined
            if (this.fileAndSignature) {
                finalData = this.fileAndSignature
            } else {
                finalData = this.fileUrl
            }

            engine.signe_and_change_file_name(
                this.fileIsSelectedData.path,
                finalData,
                function () {
                    that.closeModal()
                })
        },
        save() {
            const { data } = this.$refs.signaturePad.saveSignature();
            this.signatureBase64 = data
            // this.addSignatureInFile(data)
            // // Créer un nouveau canvas
            // var canvas = document.createElement("canvas");
            // var ctx = canvas.getContext("2d");

            // ctx.canvas.width = this.fileSize.width;
            // ctx.canvas.height = this.fileSize.height;

            // // Charger les deux images
            // var signature = new Image();
            // signature.src = data;
            // signature.height = this.fileSize.height
            // signature.width = this.fileSize.width


            // var fond = new Image();
            // fond.src = this.fileUrl;
            // fond.height = this.fileSize.height
            // fond.width = this.fileSize.width

            // ctx.drawImage(fond, 0, 0, this.fileSize.width, this.fileSize.height);
            // ctx.drawImage(signature, 0, 0, this.fileSize.width, this.fileSize.height);

            // var imageData = canvas.toDataURL("image/jpeg", 1.0)
            // let newName = this.fileIsSelectedData.fileName.split('.')[0].split('_')
            // newName[2] = moment().format('DD-MM-YYYY|hh-mm')
            // newName = newName.join('_') + '.' + this.fileIsSelectedData.fileName.split('.')[1]
            // if (show) {
            //     engine.signe_and_move_file(
            //         this.fileIsSelectedData.clientFolder,
            //         this.fileIsSelectedData.fileName,
            //         newName,
            //         imageData,
            //         function () {
            //             that.closeModal()
            //         })
            // }
        },
    },
    computed: {
        isEditable() {
            return this.fileIsSelectedData.isSigned == 'NOK'
        },

    },
    props: {
        fileIsSelectedData: {}
    },
    components: {
        SignaturePad,
        VuePdfApp
    }
}
</script>

<style></style>