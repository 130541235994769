<template>
  <div align="center" class="">
    <v-card class="pa-2" variant="text" style="border-radius: 0px; background-color: white">
      <div style="text-transform: uppercase; font-weight: bold" class="pb-2">
        <!-- Filtrer par agences -->
        <!-- Liste des agences -->
        Séléctionner un point de départ
      </div>
      <v-select variant="outlined" value="Sélectionner une agence de départ" :disabled="isPathLoaded" :items="position"
        hide-details v-model="localAgencyFilter" item-title="client" density="compact"
        no-data-text="Aucun trajets disponible">
      </v-select>
      <!-- <v-card height="100px">
        <v-col>
          <v-chip v-for="elem in localAgencyFilter" :key="elem" class="mx-1">
            {{ elem }}
          </v-chip>
        </v-col>
      </v-card> -->
      <v-col class="mt-1" style="height: 70px;">
        <v-row style="background-color: #F5F5F5; max-height: 70px; overflow: auto;border-radius: 0px;"
          class="pa-1">
          <v-col v-for="(elem, i) in itineraireOrder.data" :key="i" cols="auto" class="pa-0">
            <div style="background-color: #757575;color:#FAFAFA;border-radius: 0px;font-size: 9px;margin: 1px 1px;font-weight: bold;" class="pa-1">
              {{ i + 1 }} - {{ elem.clients[0] }} <v-icon size="13">mdi-arrow-right</v-icon> {{ elem.clients[1] }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-divider class="my-3"></v-divider>
      <div style="text-transform: uppercase; font-weight: bold" class="pb-2">
        trajets disponibles
      </div>
      <div style="
                            height: 67vh;
                            overflow-y: auto;
                            position: relative;
                            border: solid 1px lightgrey;
                          " align="center">
        <!-- <div style="border: solid 1px lightgrey" v-if="getAgencyFiltered.length != 0"> -->
        <div v-if="localAgencyFilter">
          <v-col v-for="(elem, i) in getAgencyFiltered" :key="i" class="py-2 px-0" style="" :style="{
            'background-color': i % 2 == 0
              ? '#EEEEEE'
              : '',
          }">
            <v-row class="px-0 mx-0 trajetListe" style="cursor:pointer"   @click="addElemToMaps(elem)">
              <v-col cols="auto" class="">
                <!-- <v-chip style="font-weight:bold;width: 100%;"> -->
                  <div expand-icon="mdi-menu-down" style="position: relative;font-size: 12px; border-radius: 5px;width: 100%;" class="pa-2">
                    {{ elem.clients[0] }}
                    <v-icon class=""> mdi-arrow-right-bold </v-icon>
                    {{ elem.clients[1] }}
                  </div>
                <!-- </v-chip> -->
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn icon="" density="compact" class="mx-1" variant="text"
                  :disabled="isPathLoaded">
                  <v-icon> mdi-timeline-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </div>
        <div v-else style="padding-top: 30vh;">
          Aucune agence séléctionné
        </div>
        <!-- </div> -->
      </div>
      <v-row class="pt-2">
        <v-col cols="6" class="pa-3 pr-1">
          <v-btn width="100%" @click="showFullPath()" variant="text" color="#3F51B5" style="border: solid 1px lightgrey"
            :disabled="isPathLoaded || itineraireOrder.data.length === 0">
            Visualiser l'itinéraire
          </v-btn>
        </v-col>
        <v-col cols="6" class="pa-3 pl-1">
          <v-btn width="100%" @click="validItineraire()" variant="text" color="#81C784"
            style="border: solid 1px lightgrey" :disabled="isPathLoaded || itineraireOrder.data.length === 0">
            Valider l'itinéraire
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstLoad: true,
      localAgencyFilter: undefined,
    };
  },
  props: {
    isPathLoaded: {},
    showFullPath: {},
    getAgencyFiltered: {},
    position: {},
    itineraireOrder: {},
    setItineraireOrder: {},
    validItineraire: {},
    agencyFilter: {},
  },
  methods: {
    addElemToMaps(elem) {
      this.setItineraireOrder(elem)
      this.localAgencyFilter = elem.clients[1]
    },
  },
  watch: {
    localAgencyFilter(val) {
      this.$emit("updateFilter", val);
    },
    position(val) {
      if (val.length != 0) {
        this.localAgencyFilter = this.position[0].client
      }
    }
  },
};
</script>

<style>
</style>