import { ref } from "@vue/reactivity";
// import { watch } from "vue";
// import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
// import moment from "moment";
import engine from '../core/DataApi'

export default function savHooks() {

    const clientListe = ref([])
    const orListe = ref([])
    const parcListe = ref([])
    const stockListe = ref([])
    const technicienListe = ref([])
    const agenceListe = ref([])
    const savForm = ref({
        societeliv: { label: 'Société', key: 'societeliv', value: '', isRequired: true, isEditable: true },
        Telephone: { label: 'Téléphone', key: 'Telephone', value: '', isRequired: true, isEditable: true },
        villeliv: { label: 'Ville', key: 'villeliv', value: '', isRequired: true, isEditable: true },
        ID_Agence: { label: 'Agence', key: 'ID_Agence', value: '', isRequired: true, isEditable: true },
        ID_Societe: { label: 'Societé', key: 'ID_Societe', value: '', isRequired: true, isEditable: true },
        'N° MATR INT': { label: 'N° MATR INT', key: 'N° MATR INT', value: '', isRequired: true, isEditable: true },
        Horametre: { label: 'Horametre', key: 'Horametre', value: '', isRequired: true, isEditable: true },
        Kilometrage: { label: 'Kilometrage', key: 'Kilometrage', value: '', isRequired: true, isEditable: true },
        "MARQUE MACHINE": { label: 'Marque', key: 'MARQUE MACHINE', value: '', isRequired: true, isEditable: true },
        "N° serie constr.": { label: 'N° serie constructeur', key: 'N° serie constr.', value: '', isRequired: true, isEditable: true },
        "TYPE MACHINE": { label: 'Matricule', key: 'TYPE MACHINE', value: '', isRequired: true, isEditable: true },
        // "DATE RAPPORT": { label: '', key: '', value: '', isRequired: true, isEditable:true },
        Observ: { label: 'Observation', key: 'Observ', value: '', isRequired: true, isEditable: true },
        "Techniciens_Nom": { label: 'Technicien', key: 'Techniciens_Nom', value: '', isRequired: true, isEditable: true },
        // "Temps passé_CENT": { label: '', key: '', value: '', isRequired: true, isEditable:true },
        // "Temps passé_H": { label: '', key: '', value: '', isRequired: true, isEditable:true },
        "Theme devis": { label: 'Theme devis', key: 'Theme devis', value: '', isRequired: true, isEditable: true },
        "Travaux exe": { label: 'Travaux exécutés', key: 'Travaux exe', value: '', isRequired: true, isEditable: true },
        "Heure Arriv": { label: 'Arrivé', key: 'Heure Arriv', value: '', isRequired: true, isEditable: true },
        "Heure dep": { label: 'Départ', key: 'Heure dep', value: '', isRequired: true, isEditable: true }
    })

    const getClientListe = function() {
        engine.get_clients(function(data) {
            if (data.code == 0) {
                clientListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getParcListe = function() {
        engine.get_parc(function(data) {
            if (data.code == 0) {
                parcListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getStockListe = function() {
        engine.get_stock(function(data) {
            if (data.code == 0) {
                stockListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getOrListe = function() {
        engine.get_or_liste(function(data) {
            if (data.code == 0) {
                orListe.value = data.data.data.map((val) => val.fieldData)
                orListe.value['Status_devis'] = orListe.value['Status devis']
            }
        })
    }

    const getTechniciens = function() {
        engine.get_techniciens(function(data) {
            if (data.code == 0) {
                technicienListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }

    const getAgences = function() {
        engine.get_agences(function(data) {
            if (data.code == 0) {
                agenceListe.value = data.data.data.map((val) => val.fieldData)
            }
        })
    }


    onMounted(() => {
        getOrListe()
        getClientListe()
        getParcListe()
        getStockListe()
        getTechniciens()
        getAgences()
    })

    return { clientListe, parcListe, stockListe, savForm, orListe, technicienListe, agenceListe, getOrListe };
}