<template>
    <div>
        <v-dialog v-model="isOrVerificationOpen" width="auto">
            <v-card>
                <v-col style="font-weight: bold;">
                    Valider ma supression ?
                </v-col>
                <v-col>
                    <v-row>
                        <v-col>
                            <v-btn color="#4CAF50" style="color:white" @click="valideSuppresion()">
                                Oui
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="#EF5350" style="color:white" @click="isOrVerificationOpen = false">
                                Non
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isOrModalOpen" width="98%" max-width="1100">
            <v-col>
                <v-card style="overflow: auto; max-height: 90vh;">
                    <v-col>
                        <div style="position:absolute;right:10px;">
                            <v-btn icon="mdi-close-circle" density="small" variant="text" color="#E57373"
                                style="font-size: 20px;" @click="isOrModalOpen = false">
                            </v-btn>
                        </div>
                        <sav-form @close="closeModal()" :itemsData="itemsData" :technicienListe="technicienListe"
                            :agenceListe="agenceListe" :clientListe="clientListe" :parcListe="parcListe"
                            :stockListe="stockListe" :FormData="savForm"></sav-form>
                    </v-col>
                </v-card>
            </v-col>
        </v-dialog>
        <v-card class="pa-1 mx-1 mb-2">
            <v-col>
                <v-row align="center">
                    <v-col class="" style="text-transform: uppercase;">
                        <h3>Liste des interventions</h3>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn variant="flat" color="rgb(92, 107, 192)" style="color:white"
                            @click="openDialog(undefined)">Créer un
                            OR
                        </v-btn>
                        <v-btn icon="mdi-refresh" density="compact" style="font-size:20px" variant="text" size="40"
                            @click="getOrListe" class="ml-3"></v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
        <v-card class="pa-1 mx-1">
            <v-col cols="12">
                <v-card color="#ECEFF1" variant="flat">
                    <v-text-field hide-details v-model="search" label="Recherche" clearable></v-text-field>
                </v-card>
            </v-col>
            <v-col>
                <Vue3EasyDataTable table-class-name="customize-table" ref="dataTable" :headers="get_tab_header"
                    :items="get_or_liste_filtered" style="overflow: auto" :table-height="windowHeight * 0.68"
                    rows-per-page-message="Ligne par colonne" rows-of-page-separator-message="total"
                    empty-message="Aucune intervention trouvé">
                    <template #item-Status_devis="item">
                        <div align=center class="pa-1">
                            <v-chip v-if="item['Status devis'] == 'Signé'" color="white"
                                style="font-weight: bold; background-color: green;">
                                {{ item['Status devis'] }}
                            </v-chip>
                            <v-chip v-else-if="item['Status devis'] == 'en cours'" color="white"
                                style="font-weight: bold; background-color: #FFA726;">
                                {{ item['Status devis'] }}
                            </v-chip>
                            <v-chip v-else style="font-weight: bold;">
                                {{ item['Status devis'] }}
                            </v-chip>
                        </div>
                    </template>
                    <template #item-delete="item">
                        <div class="pa-1" align="center">
                            <v-btn icon="mdi-delete" size="35" style="color:white;font-size: 14px;" color="#EF5350"
                                @click="deleteOr(item)">
                            </v-btn>
                        </div>
                    </template>
                    <template #item-show="item">
                        <div class="pa-1" style="">
                            <v-btn icon="mdi-open-in-app" size="35"
                                style="border-radius: 0px;font-size: 15px; width: 100%;color:white" color="#7986CB"
                                @click="showOr(item)">
                            </v-btn>
                        </div>
                    </template>
                </Vue3EasyDataTable>
            </v-col>
        </v-card>
    </div>
</template>

<script>

import savHooks from '../hooks/sav'
import SavForm from './sav-form.vue'
import Vue3EasyDataTable from "vue3-easy-data-table";
import engine from '../core/DataApi'

export default {
    setup() {
        const { clientListe, parcListe, stockListe, savForm, orListe, technicienListe, agenceListe, getOrListe } = savHooks()
        return { clientListe, parcListe, stockListe, savForm, orListe, technicienListe, agenceListe, getOrListe };
    },
    data() {
        return {
            selectedOrData: undefined,
            isOrVerificationOpen: false,
            itemsData: undefined,
            search: "",
            windowHeight: window.innerHeight,
            isOrModalOpen: false
        }
    },
    mounted() {
        this.onResize()
    },
    computed: {
        get_tab_header() {
            let tab_header = [];
            let list = [
                {
                    key: 'N° RAP COMP',
                    label: 'Numéro'
                }, {
                    key: 'societeliv',
                    label: 'Société'
                }, {
                    key: 'N° MATR INT',
                    label: 'Matricule'
                }, {
                    key: 'MARQUE MACHINE',
                    label: 'Marque'
                }, {
                    key: 'villeliv',
                    label: 'Ville'
                }, {
                    key: 'show',
                    label: 'Visualiser'
                },
                //  {
                //     key: 'Status_devis',
                //     label: 'État'
                // }, 
                {
                    key: 'delete',
                    label: 'Supprimer'
                }
            ];
            for (let i = 0; i < list.length; i++) {
                tab_header.push({
                    text: list[i].label,
                    value: list[i].key,
                    sortable: true
                });
            }
            return tab_header;
        },
        get_or_liste_filtered() {
            let liste = []

            if (this.search == undefined || this.search.length == 0) {
                liste = this.orListe
            }
            else {
                for (let i = 0; i < this.orListe.length; i++) {
                    let inter = this.orListe[i]
                    let elems = this.get_tab_header.map((val) => val.value)
                    let key = ""
                    for (let a = 0; a < elems.length; a++) {
                        key += inter[elems[a]]
                    }
                    if (key.includes(this.search))
                        liste.push(inter)
                }
            }

            return liste.sort((a, b) => b.INDEX - a.INDEX)
        }
    },
    methods: {
        valideSuppresion() {
            let that = this
            engine.delete_or_by_id(this.selectedOrData.id, function () {
                that.getOrListe()
                that.selectedOrData = undefined
            })
            this.isOrVerificationOpen = false
        },
        showOr(orData) {
            this.openDialog(orData)
        },
        deleteOr(orData) {
            this.isOrVerificationOpen = true
            this.selectedOrData = orData
        },
        closeModal() {
            this.isOrModalOpen = false
            this.getOrListe()
        },
        onResize() {
            this.windowHeight = window.innerHeight
        },
        openDialog(data) {
            this.itemsData = data
            this.isOrModalOpen = true
        }
    },
    components: {
        SavForm,
        Vue3EasyDataTable
    }
}
</script>

<style>
.customize-table {
    --easy-table-border: 1px solid lightgrey;
    --easy-table-row-border: 1px solid lightgrey;

    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: black;
    --easy-table-header-background-color: #ECEFF1;

    --easy-table-header-item-padding: 10px 15px;

    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #4c5d7a;

    --easy-table-body-row-font-color: black;
    --easy-table-body-row-background-color: #ECEFF1;
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 14px;

    --easy-table-body-row-hover-font-color: black;
    --easy-table-body-row-hover-background-color: lightgray;

    /* --easy-table-body-item-padding: 10px 15px; */

    --easy-table-footer-background-color: #ECEFF1;
    --easy-table-footer-font-color: black;
    --easy-table-footer-font-size: 14px;
    --easy-table-footer-padding: 0px 10px;
    --easy-table-footer-height: 50px;

    --easy-table-rows-per-page-selector-width: 70px;
    --easy-table-rows-per-page-selector-option-padding: 10px;
    --easy-table-rows-per-page-selector-z-index: 1;


    --easy-table-scrollbar-track-color: #ECEFF1;
    --easy-table-scrollbar-color: #ECEFF1;
    --easy-table-scrollbar-thumb-color: #4c5d7a;
    --easy-table-scrollbar-corner-color: #ECEFF1;

    --easy-table-loading-mask-background-color: #ECEFF1;
}
</style>